import { render, staticRenderFns } from "./index.vue?vue&type=template&id=43c0e414&scoped=true"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&id=43c0e414&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43c0e414",
  null
  
)

export default component.exports